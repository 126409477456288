import {useContext} from "react";
import {InView} from "react-intersection-observer";

import {AIContext} from "../../Router";

interface Props{
    id?: string,
    className?: string,
    children?: JSX.Element | JSX.Element[];
    background: string,
    threshold?: number | number[],
    rootMargin? : string
}

let previousY = 0
let previousRatio = 0

function AISection({children, className, id, background, threshold, rootMargin}:Props){

    //Context
    const value = useContext(AIContext)

    return(
        // <InView as={'section'}
        //         id={id}
        //         className={className}
        //         triggerOnce={false}
        //         rootMargin={rootMargin ? rootMargin : value.screenSize === 'desktop-fhd' || value.screenSize === 'desktop-max' ? '0% 0px -10% 0px' : '0% 0px 0% 0px'}
        //         threshold={threshold ? threshold : .1}
        //         onChange={(inView, entry) =>(
        //             inView ? value.setActiveBackground(background) : 'white')
        //         }
        // >
        //     {children}
        // </InView>
        <InView as={'section'}
                id={id}
                className={className}
                triggerOnce={false}
                rootMargin={rootMargin ? rootMargin : value.screenSize === 'desktop-fhd' || value.screenSize === 'desktop-max' ? '0% 0px 0% 0px' : '0% 0px 0% 0px'}
                threshold={threshold ? threshold : 0.1}
                onChange={(inView, entry) => {
                    return inView ? value.setActiveBackground(background) : 'white'
                    }
                }
        >
            {children}
        </InView>
    )

}

export default AISection;