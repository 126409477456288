import React, { useContext } from 'react';
import { AIContext } from "../../Router";
import { Col, Container, Row } from "react-bootstrap";
import AIForms from "../AIForms/AIForms";

interface FormFieldType{
  type: string,
  title: string,
  id:string,
  value:string,
  required:boolean,
  validationText?:string,
  validationPair?:string
}

const AiCareerApply = () => {

  function handleMouseOver (){
    value.setIsHovering(true)
  }

  function handleMouseLeave(){
    value.setIsHovering(false)
  }

  /*
  Context
  =============*/
  const value = useContext(AIContext)

  /*
  Form Data
  =============*/
  const formData:FormFieldType[] = [
    {
      type: 'input',
      title: 'First Name*',
      id: 'first_name',
      value:'',
      required: true,
      validationText: 'First name is required'
    },
    {
      type: 'input',
      title: 'Last Name*',
      id: 'last_name',
      value:'',
      required: true,
      validationText: 'Last name is required'
    },
    {
      type: 'email',
      title: 'Email*',
      id: 'email',
      value:'',
      required: true,
      validationText: 'The Email is required.',
      validationPair: 'phone'
    },
    {
      type: 'phone',
      title: 'Phone',
      id: 'phone',
      value:'',
      required: true,
      validationText: 'The Phone is required.',
      validationPair: 'email'
    },
    {
      type: 'input',
      title: 'Portfolio link',
      id: 'portfolio',
      value:'',
      required: false,
    },
    {
      type: 'upload',
      title: 'Attach Cover Letter',
      id: 'cover_letter',
      value:'',
      required: false
    },
    {
      type: 'upload',
      title: 'Attach Cover Letter',
      id: 'cover_letter',
      value:'',
      required: false
    },
    {
      type: 'upload',
      title: 'Attach Portfolio',
      id: 'portfolio_attachment',
      value:'',
      required: true,
      validationText: 'Resume is required'
    },
    {
      type: 'textarea',
      title: 'Personal Note',
      id: 'note',
      value:'',
      required: false
    },
    {
      type: 'checkbox',
      title: 'I agree that you can keep my data for an extended time period so that it will be easier for you to contact me about job opportunities.',
      id: 'terms',
      value:'',
      required: true,
      validationText: 'Agreement is required'
    },
  ]

  function submitForm(formData:any) {
    //Submit form here
    console.log(formData)
  }

  return(
      <section className={`ai-contact-us apply-form ${value.applyOpen ? 'open' : 'closed'}`}>
        <Container className={'g-0'}>
          <Row className={'ai-pt-0 ai-pb-5 g-0'}>
            <Col lg={12} className={'ai-contact-us__title'}>
              <h1>Apply.</h1>
              <p>We look forward to hearing from you. </p>
            </Col>
          </Row>
          <Row className={'g-0 ai-pt-0 ai-pb-5 ai-contact-us__content'}>
            <Col lg={{offset: 5, span: 6}} className={'ai-contact-us__content-intro'}>
              <AIForms FormData={formData} submitForm={submitForm}/>
            </Col>
          </Row>
        </Container>
      </section>
  )
};

export default AiCareerApply;