import React, {useEffect, useRef, useState} from "react";
import {useInView} from "react-intersection-observer";

interface Props{
    text?: string,
    fontSize?: string,
    fontWeight?: string,
    children?: JSX.Element,
    lineIndex?: number

}

function SplitText({text, fontSize, fontWeight, children, lineIndex}:Props){
    //Intersection Observer
    const { ref, inView } = useInView({
        threshold: 0,
        rootMargin: '-100px',
        triggerOnce: true
    });

    /*
    * useRefs
    * */
    const [lines, setLines] = useState([])
    const testRef = useRef<any>(null)
    const textContainer = useRef<any>(null)
    const [animationComplete, setAnimationComplete] = useState(false);
    useEffect(() => {
        const testEl = testRef.current
        let line:any = []
        let output:any  = []

        text  && text.split(' ').forEach((word) => {
            testEl.innerHTML = (testEl.innerHTML + ' ' + word).trim()

            if (testEl.scrollWidth > testEl.clientWidth) {
                testEl.innerHTML = word
                output = output.concat(line.join(' '))
                line = []
            }

            line = line.concat(word)
        })

        output = output.concat(line.join(' '))
        setLines(output)
    }, [text])


    useEffect(() => {
        setAnimationComplete(true);
    }, [lines]);

    return(
        <>
            {!animationComplete && <span className={'test-lines ai-split-line'} ref={testRef}></span>}
            <span ref={ref} className={'ai-split-text-container'}>
                <span className={`ai-split-line ${inView && 'animate'}`} ref={textContainer}>
                    {
                        lines.map((line:string, index: number)=>(
                            <span className={'line'} key={index} style={{"--animation-delay": index + 1} as React.CSSProperties}>
                                {line}
                            </span>
                        ))
                    }
                </span>
            </span>
            {children &&
                <span ref={ref} className={'ai-split-text-container'}>
                    <span className={`ai-split-line ${inView && 'animate'}`} ref={textContainer}>
                        <span className={'line'} style={{"--animation-delay": lineIndex ? lineIndex : 0} as React.CSSProperties}>{children}</span>
                    </span>
                </span>
            }

        </>
    )
}

export default SplitText;