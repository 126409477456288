import React from 'react';
import { Helmet } from "react-helmet";
import AISection from "../components/AISection/AISection";
import { Col, Container, Row } from "react-bootstrap";
import AITitle from "../components/AITitle/AITitle";
import SplitText from "../functions/SplitText";


//Assets
import WhatWeOfferImage from '../assets/images/GraduationPage/kinga-what-we-offer-image.png';
import WhatWeOfferImageMobile from '../assets/images/GraduationPage/kinga-what-we-offer-mobile-img.png';
import AIForms from "../components/AIForms/AIForms";

interface FormFieldType{
  type: string,
  title: string,
  id:string,
  value:string,
  required:boolean,
  validationText?:string,
  validationPair?:string
}


const GraduationPage = () => {

  /*
Form Data
=============*/
  const formData:FormFieldType[] = [
    {
      type: 'input',
      title: 'Name and surname',
      id: 'full_name',
      value:'',
      required: true,
      validationText: 'Name and surname is required'
    },
    {
      type: 'phone',
      title: 'Contact number',
      id: 'contact_number',
      value:'',
      required: true,
      validationText: 'contact number is required'
    },
    {
      type: 'email',
      title: 'Email',
      id: 'email',
      value:'',
      required: true,
      validationText: 'Email is required'
    },
    {
      type: 'input',
      title: 'Portfolio link',
      id: 'portfolio',
      value:'',
      required: false,
    },
    {
      type: 'upload',
      title: 'Attach CV',
      id: 'cv',
      value: '',
      required: false
    }
  ]

  function submitForm(formData:any) {
    //Submit form here
    console.log(formData)
  }

  return (
      <>
        <Helmet>
          <title>Grad programme | Kinga </title>
          <meta name="description" content={"Looking to kick-start your career in digital design? Our grad programme is for you."}/>
          <meta name="description" property={"og:description"} content={"Looking to kick-start your career in digital design? Our grad programme is for you."}/>
          <meta property="og:locale" content={"en_US"} />
          <meta property="og:type" content={"website"} />
          <meta property="og:title" content={"Careers | Kinga"} />
          <meta property="og:site_name" content={"Kinga"} />
          <meta property="og:url" content={'https://kingaglobal.com/careers/'} />
        </Helmet>
        <AISection background={'yellow'} className={'ai-grad-programme-header'} threshold={0.1}>
          <Container className={'g-0'}>
            <Row className={'ai-pt-0 g-0 align-items-end'}>
              <Col lg={6} className={'ai-careers-header-text'}>
                <Row className={'g-0'}>
                  <Col lg={12}>
                    <AITitle className={'ai-mb-2'} tag={'h1'} lines={2} lineOne={'Grad'} lineTwo={'programme'}/>
                    <div className="ai-black-circle"></div>
                    <p>
                      <SplitText text={'Looking to kick-start your career in digital design? Our grad programme is for you.'}/>
                    </p>
                  </Col>
                </Row>
              </Col>
            </Row>
          </Container>
        </AISection>
        {/*WE WANT YOU SECTION*/}
        <AISection background={'black'} threshold={0.5} className={'ai-we-want-you-section'}>
          <Container>
            <Row>
              <Col lg={8} sm={12}>
                <AITitle tag={'h2'} className={'ai-text-white'} text={'We want you.'}/>
                <p className={'ai-text-white ai-text-32'}><strong><SplitText text={'No experience? No problem!'}/></strong></p>
                <p className={'ai-text-white'}><SplitText text={'Even if you’re fresh out of college, we’re here to get you on track for success. If you’ve studied design, research, development or psychology - we want to link you to some of South Africa’s biggest brands for real world experience and a strong career start.'}/></p>
              </Col>
            </Row>
          </Container>
        </AISection>
        {/*WE WANT YOU SECTION*/}
        <AISection background={'white'} className={'ai-what-we-offer-section'} threshold={0.5}>
          <Container>
            <Row>
              <Col lg={4} md={12}>
                <AITitle tag={'h3'} className={'ai-text-32'} text={'Here’s what we offer'}/>
                <ul>
                  <li className={'ai-text-small ai-m-pb-2'}><SplitText text={"Kick-start a meaningful career"}/></li>
                  <li className={'ai-text-small ai-m-pb-2'}><SplitText text={"Earn a competitive salary"}/></li>
                  <li className={'ai-text-small ai-m-pb-2'}><SplitText text={"Get industry exposure with real clients"}/></li>
                  <li className={'ai-text-small ai-m-pb-2'}><SplitText text={"Collaborate on a career growth trajectory"}/></li>
                  <li className={'ai-text-small ai-m-pb-2'}><SplitText text={"Up to 6 months of guaranteed paid experience."}/></li>
                  <li className={'ai-text-small ai-m-pb-2'}><SplitText text={"Upskill yourself in your field of expertise"}/></li>
                  <li className={'ai-text-small ai-m-pb-2'}><SplitText text={"Access extensive learning opportunities"}/></li>
                  <li className={'ai-text-small ai-m-pb-2'}><SplitText text={"Learn about the business and corporate ecosystem, and ways of working in a client environment"}/></li>
                  <li className={'ai-text-small ai-m-pb-0 '}><SplitText text={"Be part of world class talent"}/></li>
                </ul>
              </Col>
              <Col lg={8} md={12}>
                <img className={'ai-what-we-offer-image desktop'} src={WhatWeOfferImage}/>
                <img className={'ai-what-we-offer-image mobile'} src={WhatWeOfferImageMobile}/>
                <div className="ai-black-circle"></div>
              </Col>
            </Row>
          </Container>
        </AISection>
        {/*Get Started Section*/}
        <AISection background={'yellow'} className={'ai-get-started-section'}  threshold={0.5}>
          <Container>
            <Row>
              <Col lg={8}>
                <AITitle tag={'h2'} lines={2} lineOne={'Ready to get'} lineTwo={'started?'}/>

                <p className={'ai-text-32'}><strong><SplitText text={'This is what the process looks like:'}/></strong></p>
              </Col>
            </Row>
            <Row className={'ai-pt-0 ai-get-started-steps'}>
              <Col lg={3} className={'ai-mb-4 ai-m-mb-0'}>
                <span className={'ai-get-started-steps__number'}>
                  01
                </span>
                <span className={'ai-get-started-steps__description'}>
                  <SplitText text={'Apply'}/>
                </span>
              </Col>
              <Col lg={3} className={'ai-mb-4 ai-m-mb-0'}>
                <span className={'ai-get-started-steps__number ai-text-white'}>
                  02
                </span>
                <span className={'ai-get-started-steps__description'}>
                  <SplitText text={'Interview'}/>
                </span>
              </Col>
              <Col lg={3} className={'ai-mb-4 ai-m-mb-0'}>
                <span className={'ai-get-started-steps__number ai-text-white'}>
                  03
                </span>
                <span className={'ai-get-started-steps__description'}>
                  <SplitText text={'Design challenge'}/>
                </span>
              </Col>
              <Col lg={3} className={'ai-mb-4 ai-m-mb-0'}>
                <span className={'ai-get-started-steps__number no-line ai-text-white'}>
                  04
                </span>
                <span className={'ai-get-started-steps__description'}>
                  <SplitText text={'Hire'}/>
                </span>
              </Col>
              <Col lg={3}>
                <span className={'ai-get-started-steps__number ai-text-white'}>
                  05
                </span>
                <span className={'ai-get-started-steps__description'}>
                  <SplitText text={'Place at client'}/>
                </span>
              </Col>
              <Col lg={3}>
                <span className={'ai-get-started-steps__number ai-text-white'}>
                  06
                </span>
                <span className={'ai-get-started-steps__description'}>
                  <SplitText text={'Performance review'}/>
                </span>
              </Col>
              <Col lg={3}>
                <span className={'ai-get-started-steps__number no-line ai-text-white'}>
                  07
                </span>
                <span className={'ai-get-started-steps__description'}>
                  <SplitText text={'Employment contract'}/>
                </span>
              </Col>
            </Row>
          </Container>
        </AISection>
        {/*FORM SECTION*/}
        <AISection background={'white'} className={'ai-grad-programme-form-section'} threshold={0.5}>
          <Container>
            <Row className={'ai-pb-2'}>
              <Col lg={12}>
                <AITitle tag={'h2'} text={'Apply.'}></AITitle>
                <p className={'ai-text-32'}><strong><SplitText text={'We look forward to hearing from you. '}/></strong></p>
              </Col>
            </Row>
            <Row className={'ai-pt-0'}>
              <AIForms FormData={formData} submitForm={submitForm}/>
            </Row>
          </Container>
        </AISection>
        </>
  );
};

export default GraduationPage;