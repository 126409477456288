import {useInView} from "react-intersection-observer";
import {useContext} from "react";
import {AIContext} from "../../Router";

interface Props{
    text?: string,
    tag: string,
    lines?: number,
    lineOne?:string,
    lineTwo?: string,
    lineThree?: string,
    className?: string
}

function AITitle({text, tag, lineOne, lineTwo, lineThree, lines, className}: Props){

    //context
    const value = useContext(AIContext)


    const { ref, inView } = useInView({
        threshold: 0,
        rootMargin: value.screenSize === 'mobile' ? '-150px' : tag === 'h4' && value.screenSize === 'mobile' ? '-10px' : tag === 'h4' ? '-100px' : '-200px',
        triggerOnce: true
    });

    switch (tag){
        case 'h1':
            return(
                lines === 2 ?
                <div className={'ai-animated-line-wrapper'}>
                  <h1 className={`ai-title-animation ${className} ${inView && 'animated'}`} ref={ref}>
                    <span className={'title-text'}>{lineOne}</span>
                    <div className={'title-overlay'}></div>
                  </h1><br/>
                  <h1 className={`ai-title-animation ${className} ${inView && 'animated'}`} ref={ref}>
                    <span className={'title-text'}>{lineTwo}</span>
                    <div className={'title-overlay'}></div>
                  </h1>
                </div>:
                <h1 className={`ai-title-animation ${className} ${inView && 'animated'}`} ref={ref}>
                    <span className={'title-text'}>{text}</span>
                    <div className={'title-overlay'}></div>
                </h1>
            )
        case 'h2':
            return (
                lines === 2 ?
                    <div className={'ai-animated-line-wrapper'}>
                        <h2 className={`ai-title-animation ${className} ${inView && 'animated'}`} ref={ref}>
                            <span className={'title-text'}>{lineOne}</span>
                            <div className={'title-overlay'}></div>
                        </h2><br/>
                        <h2 className={`ai-title-animation ${className} ${inView && 'animated'}`} ref={ref}>
                            <span className={'title-text'}>{lineTwo}</span>
                            <div className={'title-overlay'}></div>
                        </h2>
                    </div>:
                    <h2 className={`ai-title-animation ${className} ${inView && 'animated'}`} ref={ref}>
                        <span className={'title-text'}>{text}</span>
                        <div className={'title-overlay'}></div>
                    </h2>
            )
        case 'h3':
            return (
                lines === 2 ?
                    <div className={'ai-animated-line-wrapper'}>
                        <h3 className={`ai-title-animation ${className} ${inView && 'animated'}`} ref={ref}>
                            <span className={'title-text'}>{lineOne}</span>
                            <div className={'title-overlay'}></div>
                        </h3> <br/>
                        <h3 className={`ai-title-animation ${className} ${inView && 'animated'}`} ref={ref}>
                            <span className={'title-text'}>{lineTwo}</span>
                            <div className={'title-overlay'}></div>
                        </h3>
                    </div>:
                    lines === 3 ?
                    <div className={'ai-animated-line-wrapper'}>
                      <h3 className={`ai-title-animation ${className} ${inView && 'animated'}`} ref={ref}>
                        <span className={'title-text'}>{lineOne}</span>
                        <div className={'title-overlay'}></div>
                      </h3> <br/>
                      <h3 className={`ai-title-animation ${className} ${inView && 'animated'}`} ref={ref}>
                        <span className={'title-text'}>{lineTwo}</span>
                        <div className={'title-overlay'}></div>
                      </h3> <br/>
                      <h3 className={`ai-title-animation ${className} ${inView && 'animated'}`} ref={ref}>
                        <span className={'title-text'}>{lineThree}</span>
                        <div className={'title-overlay'}></div>
                      </h3>
                    </div>:
                    <h3 className={`ai-title-animation ${className} ${inView && 'animated'}`} ref={ref}>
                        <span className={'title-text'}>{text}</span>
                        <div className={'title-overlay'}></div>
                    </h3>
            )
      case 'h4':
        return (
            lines === 2 ?
                <div className={'ai-animated-line-wrapper'}>
                  <h4 className={`ai-title-animation ${className} ${inView && 'animated'}`} ref={ref}>
                    <span className={'title-text'}>{lineOne}</span>
                    <div className={'title-overlay'}></div>
                  </h4> <br/>
                  <h4 className={`ai-title-animation ${className} ${inView && 'animated'}`} ref={ref}>
                    <span className={'title-text'}>{lineTwo}</span>
                    <div className={'title-overlay'}></div>
                  </h4>
                </div>:
                <h4 className={`ai-title-animation ${className} ${inView && 'animated'}`} ref={ref}>
                  <span className={'title-text'}>{text}</span>
                  <div className={'title-overlay'}></div>
                </h4>
        )
        default:
            return <>{text}</>
    }

}

export default AITitle;