import React, { useContext, useState } from 'react';
import { useForm } from "react-hook-form";
import {ReactComponent as SliderBtnIcon} from '../../assets/images/FooterComponent/kinga-scroll-top-icon.svg'
import SplitText from "../../functions/SplitText";
import { AIContext } from "../../Router";
import { Simulate } from "react-dom/test-utils";
import error = Simulate.error;


interface Props{
  FormData:FormFieldType[],
  submitForm:Function
}

interface FormFieldType{
  type: string,
  title: string,
  id:string,
  value:string,
  required?: boolean,
  validationText?:string,
  validationPair?:string
}

const AiForms = ({FormData, submitForm}:Props) => {
  /*
  Context
  =============*/
  const value = useContext(AIContext);

  /*
  Variables
  =============*/
  //Component State
  const [submitted, setSubmitted] = useState(false);

  //REACT HOOK FORM SETUP
  const { register, formState: { errors }, handleSubmit } = useForm();

  //Submit Data Here
  const onSubmit = handleSubmit(data => {
    //Submitted true to display thank you message
    submitForm(data)
    setSubmitted(true)
  });

  /*
  Functions
  =============*/
  //Cursor Size
  function handleMouseOver (){
    value.setIsHovering(true)
  }

  function handleMouseLeave(){
    value.setIsHovering(false)
  }

  function handleValidation(field:FormFieldType, formData:FormFieldType[]){
    if(!field.validationPair || !field.required){return}
    return field.required
  }


  return (
      !submitted ?
      <form className={'ai-form'} onSubmit={onSubmit}>
        {
          FormData.map((formField:FormFieldType, index)=> {
            if(formField.type === 'email'){
              //   EMAIL
              return (
                  <div key={index} className={`ai-form-field ${formField.type} `} onMouseOver={handleMouseOver} onMouseLeave={handleMouseLeave}>
                    <label htmlFor={formField.id}>{formField.title}</label>
                    <input type={"email"} {...register(formField.id, {required: handleValidation(formField, FormData)})}/>
                    {errors[formField.id] && <span className={'ai-error-message'}>{formField.validationText ? formField.validationText : 'Please enter a value'}</span>}
                  </div>
              )
            } else if(formField.type === 'phone'){
              //   PHONE
              return (
                  <div key={index} className={`ai-form-field ${formField.type} `} onMouseOver={handleMouseOver} onMouseLeave={handleMouseLeave}>
                    <label htmlFor={formField.id}>{formField.title}</label>
                    <input type={"tel"} id={formField.id} {...register(formField.id, {required: handleValidation(formField, FormData)})}/>
                    {errors[formField.id] && <span className={'ai-error-message'}>{formField.validationText ? formField.validationText : 'Please enter a value'}</span>}
                  </div>
              )
            } else if(formField.type === 'checkbox'){
            //   CHECKBOX
              return (
                  <div key={index} className={`ai-form-field ${formField.type} `} onMouseOver={handleMouseOver} onMouseLeave={handleMouseLeave}>
                    <label htmlFor={formField.id}>
                      <input id={formField.id} type={"checkbox"} {...register(formField.id, {required:formField.required})}/>
                      <span className={'ai-checkbox'}></span>
                      <span className={'ai-checkbox-content'}>{formField.title}</span>
                    </label>
                    {errors[formField.id] && <span className={'ai-error-message'}>{formField.validationText ? formField.validationText : 'Please enter a value'}</span>}
                  </div>
              )

            } else if(formField.type === 'textarea'){
            //   TEXT AREA
              return(
                  <div key={index} className={`ai-form-field ${formField.type} `} onMouseOver={handleMouseOver} onMouseLeave={handleMouseLeave}>
                    <label htmlFor={formField.id}>{formField.title}</label>
                    <input type={"textarea"} {...register(formField.id, {required:formField.required })}/>
                    {errors[formField.id] && <span className={'ai-error-message'}>{formField.validationText ? formField.validationText + '123' : 'Please enter a value'}</span>}
                  </div>
              )
            } else if(formField.type === 'upload'){
            //   UPLOAD
              return (
                  <div key={index} className={`ai-form-field ${formField.type} `} onMouseOver={handleMouseOver} onMouseLeave={handleMouseLeave}>
                    <label className={'ai-file-label'} htmlFor={formField.id}>
                      {formField.title}
                      <input id={formField.id} type={"file"} {...register(formField.id)}/>
                    </label>
                    {errors[formField.id] && <span className={'ai-error-message'}>{formField.validationText ? formField.validationText : 'Please enter a value'}</span>}
                </div>
              )
            } else {
              // TEXT
              return (
                  <div key={index} className={`ai-form-field ${formField.type} `} onMouseOver={handleMouseOver} onMouseLeave={handleMouseLeave}>
                    <label htmlFor={formField.id}>{formField.title}</label>
                    <input  {...register(formField.id,{required:formField.required})} />
                    {errors[formField.id] && <span className={'ai-error-message'}>{formField.validationText ? formField.validationText : 'Please enter a value'}</span>}
                  </div>
              )
            }
          })
        }
        <button className={'ai-button ai-button-submit'} type={"submit"} onMouseOver={handleMouseOver} onMouseLeave={handleMouseLeave}>Send <span className={'ai-submit-icon'}><SliderBtnIcon/></span> </button>
      </form> :
          <div className={'ai-form-thank-you'}>
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path d="M2 8.99997H5V21H2C1.44772 21 1 20.5523 1 20V9.99997C1 9.44769 1.44772 8.99997 2 8.99997ZM7.29289 7.70708L13.6934 1.30661C13.8693 1.13066 14.1479 1.11087 14.3469 1.26016L15.1995 1.8996C15.6842 2.26312 15.9026 2.88253 15.7531 3.46966L14.5998 7.99997H21C22.1046 7.99997 23 8.8954 23 9.99997V12.1043C23 12.3656 22.9488 12.6243 22.8494 12.8658L19.755 20.3807C19.6007 20.7554 19.2355 21 18.8303 21H8C7.44772 21 7 20.5523 7 20V8.41419C7 8.14897 7.10536 7.89462 7.29289 7.70708Z"></path></svg>
            <p><strong><SplitText text={'Thank you!'}/></strong></p>
            <p className={'ai-text-small'}><SplitText text={'Your application has been submitted. Good luck!'}/></p>
          </div>
  );
};

export default AiForms;